import React from 'react'
// import Layout from '../components/layout'
import Header from '../components/Header.js'
import Footer from '../components/Footer.js'
import HeadTags from '../components/Headtags3'

const Videprinter = () => (
  <>
    <HeadTags />
    <div style ={{position:"relative", minHeight:"100vh"}}>
      <Header />
      <div>
      </div>
      <Footer />
    </div>
  </>
)

export default Videprinter
